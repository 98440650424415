<template>
  <div>
    <header class="el-drawer__header nanw_khan">
      <span title="Новый заказ">{{ $t("message.new_aggrement") }}</span>
      <el-button
        type="success"
        size="small"
        class="mr-1"
        @click="submitForm()"
        >{{ $t("message.create") }}</el-button
      >
      <el-button
        type="primary"
        size="small"
        class="mr-1"
        @click="submitForm(true)"
        >{{ $t("message.save_and_create_new") }}</el-button
      >
      <el-button
        type="warning"
        icon="el-icon-close"
        size="small"
        @click="close()"
        >{{ $t("message.close") }}</el-button
      >
    </header>
    <div class="body__modal">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        size="small"
        class="aticler_m stylekhanForm"
      >
        <el-row :gutter="40">
          <el-col :span="8">
            <el-form-item :label="columns.number.title" prop="number">
              <el-input
                v-model="form.number"
                :placeholder="columns.number.title"
              ></el-input>
            </el-form-item>
            <!-- end el-form-item-->

            <el-form-item :label="columns.begin_date.title" prop="begin_date">
              <el-date-picker
                v-model="form.begin_date"
                type="date"
                :placeholder="columns.begin_date.title"
                :value-format="'dd.MM.yyyy'"
              />
            </el-form-item>
            <!-- end el-form-item-->
          </el-col>

          <el-col :span="8">
            <el-form-item :label="columns.status_id.title" prop="status_id">
              <el-row :gutter="5">
                <el-col :span="22">
                  <el-form-item class="mb-0">
                    <el-select
                      v-model="form.status_id"
                      :placeholder="columns.status_id.title"
                      filterable
                      clearable
                    >
                      <el-option
                        v-for="item in statuses"
                        :key="item.id + item.title"
                        :label="item.title"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
            <!-- end el-form-item-->

            <el-form-item :label="columns.supplier_id.title" prop="supplier_id">
              <el-row :gutter="5">
                <el-col :span="22">
                  <el-form-item class="mb-0">
                    <el-select
                      v-model="form.supplier_id"
                      :placeholder="columns.supplier_id.title"
                      filterable
                      clearable
                    >
                      <el-option
                        v-for="item in suppliers"
                        :key="item.id + item.name"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="2" class="mb-0">
                  <div class="plusic" @click="drawer.provider.status = true">
                    <i class="el-icon-plus"></i>
                  </div>
                </el-col>
              </el-row>
            </el-form-item>
            <!-- end el-form-item-->
          </el-col>
          <!-- end  col -->

          <el-col :span="8">
            <el-form-item :label="columns.sum.title" prop="sum">
              <el-input
                v-model="form.sum"
                :placeholder="columns.sum.title"
              ></el-input>
            </el-form-item>
            <!-- end el-form-item-->

            <el-form-item :label="columns.description.title" prop="description">
              <el-input
                type="textarea"
                v-model="form.description"
                :placeholder="columns.description.title"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- end  col -->
        </el-row>
      </el-form>
    </div>

    <el-drawer
      size="70%"
      :wrapperClosable="false"
      :visible.sync="drawer.provider.status"
      :with-header="false"
      :ref="drawer.provider.name"
      :append-to-body="true"
      :before-close="beforeClose"
    >
      <Provider :drawer-name="drawer.provider.name" />
    </el-drawer>
  </div>
</template>
<script>
import Status from "./status-modal";
import Provider from "@/views/warehouseProvider/components/crm-create";
import { mapGetters, mapActions } from "vuex";
import drawerChild from "@/utils/mixins/drawer-child";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";

export default {
  components: { Status, Provider },
  mixins: [drawerChild, form, drawer],
  data() {
    return {
      drawer: {
        provider: {
          name: "provider",
          status: false,
        },
      },
    };
  },
  async mounted() {
    if (this.suppliers && this.suppliers.length === 0)
      await this.loadSuppliers();
    if (this.statuses && this.statuses.length === 0) await this.loadStatuses();
  },
  computed: {
    ...mapGetters({
      model: "supplierContracts/model",
      rules: "supplierContracts/rules",
      columns: "supplierContracts/columns",
      suppliers: "suppliers/inventory",
      statuses: "statuses/inventory",
    }),
  },
  methods: {
    ...mapActions({
      save: "supplierContracts/store",
      update: "supplierContracts/update",
      editModel: "supplierContracts/show",
      empty: "supplierContracts/empty",
      loadSuppliers: "suppliers/inventory",
      loadStatuses: "statuses/inventory",
    }),
    async beforeClose(done) {
      await this.loadSuppliers();
      await this.loadStatuses();
      done();
    },
    submitForm(resetForm = false) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          if (this.form.id == null) {
            this.save(this.form)
              .then((res) => {
                this.loadingButton = false;
                this.form = res.data.supplier_contract;
                this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
              })
              .catch((err) => {
                this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
                this.loadingButton = false;
              })
              .finally(() => {
                if (resetForm) {
                  this.$refs["form"].resetFields();
                  this.empty();
                }
              });
          } else {
            this.update(this.form)
              .then((res) => {
                this.loadingButton = false;
                this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
              })
              .catch((err) => {
                this.loadingButton = false;
                this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
              })
              .finally(() => {
                if (resetForm) {
                  this.$refs["form"].resetFields();
                  this.empty();
                }
              });
          }
        }
      });
    },
  },
};
</script>
